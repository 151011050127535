<template>
    <nav class="navbar">
        <img src="@/assets/img/logo.png" alt="">
    </nav>
  <!-- 至底下载按钮 -->
    <div class="positionDown" v-show="showPositionDown">
        <img src="@/assets/img/google.png" alt="" @click="handleToGoogle">
        <img src="@/assets/img/ios.png" alt="" @click="handleToAppleStore">
    </div>
    <router-view/>
</template>

<style lang="scss">
#app {
    position: relative;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding-bottom: 80px;
    background: #222625;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

nav {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 70px;
    padding: 0 16px;
    background: linear-gradient(#67DAE1, #73DEE4);
    z-index: 1000;

    img {
        width: 141px;
    }

    .navBar {
        width: 42px;
        height: 42px;
        background: rgba(255, 255, 255, 0.73);
        border-radius: 11px;
    }
}

.navbar.solid {
    position: sticky;
    top: 0;
    background: linear-gradient(#E4FEFF, #E4FEFF);
}

.menu {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #FFFFFF;
}

.positionDown {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    padding: 24px;
    background: linear-gradient(180deg, rgba(104, 218, 225, 0) 0%, #68DAE1 100%);
    z-index: 1001;

    img {
        width: 160px;
        height: 45px;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 #353535;
    }
}
</style>

<script setup>
import {onMounted, ref} from "vue";

let handleToGoogle = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=bangrui.example.quran_play";
};
let handleToAppleStore = () => {
    window.location.href = "https://apps.apple.com/us/app/quran-play-pro/id6473243349";
};

let showPositionDown = ref(false);

onMounted(() => {
    window.addEventListener('scroll', function () {
        let navbar = document.querySelector('.navbar');
        let bg2 = document.querySelector('.bg2');

        showPositionDown.value = window.scrollY >= bg2.offsetTop;

        if (window.scrollY > 0) {
            navbar.classList.add('solid');
        } else {
            navbar.classList.remove('solid');
        }
    });
});
</script>